import { DateTime } from "luxon";
import { UTCTimestamp } from "lightweight-charts";
import { ChainId, GasPriceOptionType } from "@src/pages/defi/types";
import { Address, BoolString, ISO8601DateTime } from "@src/pages/defi/types/common";
import { DefaultStatusFilter } from "@src/components/orders-table-filters/types";
import { ParentOrderFormFieldValues } from "@src/pages/cefi/order-form/hooks/use-parent-order-form";
import { WidgetsViewState } from "@src/features/widgets-layout/store/widgets.slice";
import { AnbotoButtonGroupOption } from "@src/components/ui/anboto-button-group";
import { GroupBy } from "@src/store/slices/metricsSlice";
import { PredefinedWidgetsLayout } from "@src/features/widgets-layout/types";
import { OrderbookOrders } from "@src/components/orderbook/types";

export type Allowance = {
  current_allowance: number;
  maker_address: string;
  network_id: ChainId;
  ongoing_allowance: string;
  token_address: string;
  token_decimals: number;
};

export type ValidatedData = {
  network_id: ChainId;
  maker_address: string;
  token_address: string;
};

// https://github.com/Anboto-Labs/AnbotoSmartContracts/blob/main/contracts/CompositeOrder.sol#L24
// @example:
// const order: AnbotoContractOrder = {
//     inputToken: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48", // address of USDC token
//     totalAmount: 10000_000000, // 10000 and with 6 decimals for USDC
//     outputToken: "0x6B175474E89094C44Da98b954EedeAC495271d0F", // address of DAI token
//     outMin: 9800_000000000000000000, // 2% slippage, considering 18 decimals for DAI token     (this is token pair spot price with slippage)
//     maxGasPrice: 574979400560,
//     feeAmount: 10, // in basis points, 10 is 0.1%
//     deadline: 1645735874, // unix timestamp
//     salt: 235624 // random number, for order uniqueness
// }
export type AnbotoContractOrder = {
  // from
  inputToken: string; // from|pay|sell token address
  totalAmount: string; // from|pay|sell token amount total (means without slippage - the amount what user entered)
  // to
  outputToken: string; // to|receive|buy token address
  outMin: string; // to|receive|buy token spot price with slippage subtracted
  // params
  maxGasPrice: string; // maximum gas price accepted
  feeAmount: number; // Represents maximum fee allowed in percentage, 10 is 10% of outputAmount, max value is 100
  deadline: number; // order deadline unix timestamp
  salt: number; // random additional input to make order unique
  isFeeTakenInInput: boolean;
};

export type AnbotoContractOrderV2 = {
  // from
  inputToken: string; // from|pay|sell token address
  totalAmount: string; // from|pay|sell token amount total (means without slippage - the amount what user entered)
  // to
  outputToken: string; // to|receive|buy token address
  outMin: string; // to|receive|buy token spot price with slippage subtracted
  // params
  maxFeeAbsolute: string;
  maxGasPrice: string;
  feePercent: number; // Represents maximum fee allowed in percentage, 10 is 10% of outputAmount, max value is 100
  deadline: number; // order deadline unix timestamp
  salt: number; // random additional input to make order unique
  isFeeTakenInInput: boolean;
};

export enum CefiExchangeMarketType {
  SPOT = "spot",
  FUTURE = "future",
}

export type CefiExchange = {
  id: string;
  name: string;
  market_types: CefiExchangeMarketType[];
  subaccounts: null;
};

export type Market = {
  id: string;
  symbol: string;
  name: string;
  image: string;
  current_price: number;
  market_cap: number;
  market_cap_rank: number;
  fully_diluted_valuation: number;
  total_volume: number;
  high_24h: number;
  low_24h: number;
  price_change_24h: number;
  price_change_percentage_24h: number;
  market_cap_change_24h: number;
  market_cap_change_percentage_24h: number;
  circulating_supply: number;
  total_supply: number;
  max_supply: number;
  ath: number;
  ath_change_percentage: number;
  ath_date: ISO8601DateTime;
  atl: number;
  atl_change_percentage: number;
  atl_date: ISO8601DateTime;
  roi: null;
  last_updated: ISO8601DateTime;
};

export type OrderSymbol = {
  symbol: string;
  exchange: string;
  market_type: CefiExchangeMarketType;
  coin1?: string;
  coin2?: string;
  coin_margin?: null;
  is_derivative?: boolean;
  symbol_label?: string;
  asset_class?: CefiExchangeMarketType;
};

export type CefiOrderInfos = {
  quantity: number;
  arrival_price?: number;
  executed_quantity: number;
  timestamp_next_trade?: number;
  average_closed_orders_price: number;
  estimated_end_time?: number;
};

export enum OrderStatuses {
  WAITING = "waiting",
  RUNNING = "running",
  FINISHED = "finished",
  PAUSING = "pausing",
  PAUSED = "paused",
  SERVER_PAUSING = "server_pausing",
  SERVER_PAUSED = "server_paused",
  CANCELING = "canceling",
  CANCELED = "canceled",
  INSUFFICIENT_FUNDS = "insufficient_funds",
  ERROR = "error",
  WAIT_FOR_ORDERBOOK = "wait_for_orderbook",
  WAITING_ALLOWANCE = "waiting_allowance",
}

export type CefiOrderParamsBoundParams = {
  mode: OrderTriggerMode;
  trail: null;
  max_price: 100.0;
  min_price: 0.01;
};

export type CefiOrderParamsOrderParams = {
  side: OrderSide;
  type: OrderType;
  price: number | null;
  amount: 3.0;
  params: Record<string, any>;
};

export type CefiOrderParamsRefreshParams = {
  mode: OrderRefreshMode;
  frequency: 1;
  threshold: 6.0;
  placement_level: 2.0;
};

export type CefiOrderParams = {
  frequency?: number; // in seconds
  subaccount: null | string;
  margin_type: OrderMarginType;
  bound_params: CefiOrderParamsBoundParams;
  order_params: CefiOrderParamsOrderParams;
  randomize_time: true;
  refresh_params: CefiOrderParamsRefreshParams;
  target_quantity: number;
  number_of_slices?: number;
  randomize_amount: true;
  number_of_retries: number;
  allow_switch_to_market: false;
  iceberg_speed?: OrderIcebergSpeed;
};

export enum OrderType {
  MARKET = "market",
  LIMIT = "limit",
}

export type CefiOrder = {
  uuid: string;
  id: number;
  symbol: string;
  side: OrderSide;
  target_quantity: 3.0;
  market_type: CefiExchangeMarketType;
  execution_mode: OrderExecutionStrategy;
  order_type: OrderType;
  status: OrderStatuses;
  params: CefiOrderParams;
  infos: null | CefiOrderInfos;
  data: null;
  errors: null;
  is_open_order: false;
  exchange: 1;
  exchange_name: string;
  team_id: string;
  team_name: string;
  trigger_data: string;
  role: OrderRole;
  created_at: ISO8601DateTime;
  updated_at: ISO8601DateTime;
  end_time: ISO8601DateTime;
};

export const enum OrderExecutionStrategy {
  TWAP = "twap",
  VWAP = "vwap",
  ICEBERG = "iceberg",
  ORDER = "order",
  LIMIT = "limit",
  POV = "pov",
  DCA = "dca",
  IS = "implementation_shortfall",
  FOK = "fill_or_kill",
}

export const enum DefiOrderSwapType {
  SWAP = "swap",
}

export type DefiOrderExecutionStrategy = Exclude<
  OrderExecutionStrategy,
  OrderExecutionStrategy.VWAP | OrderExecutionStrategy.IS | OrderExecutionStrategy.FOK
>;

export enum OrderSide {
  BUY = "buy",
  SELL = "sell",
}

export enum OrderRole {
  MAKER = "limit",
  TAKER = "market",
}

export type CefiOrderUpdateMessage = {
  average_closed_orders_price: string;
  bot_execution_uuid: string;
  exchange: string;
  executed_quantity: number;
  execution_mode: OrderExecutionStrategy;
  maker_taker: OrderRole;
  market_type: CefiExchangeMarketType;
  quantity: number;
  side: OrderSide;
  status: OrderStatuses;
  symbol: string;
  target_quantity: number;
  params: CefiOrderParams;
  infos: null | CefiOrderInfos;
};

export type CefiMarketBalance = {
  asset: string;
  balance: number;
  price: number;
  value: number;
};

export type CefiMarketBalances = {
  market_type: CefiExchangeMarketType;
  balance: Record<string, CefiMarketBalance>;
  total_value: number;
};

export type DefiOrderSmartContractOrder = {
  salt: number;
  outMin: number;
  deadline: number;
  feeAmount: number;
  inputToken: Address;
  maxGasPrice: number;
  outputToken: Address;
  totalAmount: number;
  totalSlices: number;
  isFeeTakenInInput: boolean;
};

export type DefiOrderInfos = {
  quantity: number;
  arrival_price?: number;
  executed_quantity: number;
  timestamp_next_trade?: number;
  average_closed_orders_price: number;
  estimated_end_time?: number;
};

export type DefiOrderParams = {
  frequency: number;
  randomize_time: BoolString;
  target_quantity: number;
  number_of_slices: number;
  randomize_amount: BoolString;
  order_expiration?: number;
  gas_speed?: GasPriceOptionType;
  order_params: {
    side: OrderSide;
    type: OrderType;
    price: null | number;
    amount: number;
    params: { slippage: number };
  };
  bound_params?: {
    mode: OrderTriggerMode;
    max_price: number;
    min_price: number;
    trail: number;
  };
};

export type DefiOrder = {
  uuid: string;
  symbol: string;
  target_quantity: number;
  market_type: null;
  execution_mode: DefiOrderExecutionStrategy;
  order_type: OrderType;
  status: OrderStatuses;
  params: DefiOrderParams;
  infos: null | DefiOrderInfos;
  data: { prices: Record<string, number>; first_order_book: {} };
  errors: { errors: string };
  network_id: ChainId;
  aggregator: string;
  target_quantity_lowest_decimals: string;
  from_token: Address;
  from_token_symbol: string;
  to_token: Address;
  to_token_symbol: string;
  maker_address: Address;
  smart_contract_address: Address;
  smart_contract_order: DefiOrderSmartContractOrder;
  team_id: string;
  team_name: string;
  created_at: ISO8601DateTime;
  updated_at: ISO8601DateTime;
  end_time: ISO8601DateTime;
};

export type DefiOrderUpdateMessage = {
  executed_quantity?: number;
  errors?: string;
  aggregator: string;
  aggregator_display: string;
  average_closed_orders_price: string;
  bot_execution_uuid: string;
  execution_mode: OrderExecutionStrategy;
  from_token: string;
  from_token_symbol: string;
  maker_taker: OrderRole;
  network_id: number;
  network_id_display: string;
  quantity: number;
  status: OrderStatuses;
  target_quantity: number;
  to_token: string;
  to_token_symbol: string;
  params: DefiOrderParams;
};

export type Exchange = {
  id: number;
  name: string;
  exchange_id: CefiExchangeId;
  is_defi: boolean;
  is_passphrase: boolean;
  is_subaccounts_support: boolean;
  is_fee_less: boolean;
};

export type ExchangeConfig = {
  uuid: string;
  team_uuid: string;
  exchange: number;
  exchange_name: string;
  exchange_id: CefiExchangeId;
  subaccount_title: null | string;
  api_keys_stars: Record<string, string>;
  error: ExchangeApiError;
};

export type ExchangeApiError = null | { type: string; text: string };

export type OrderSymbolPrice = {
  exchange_id: string;
  symbol: string;
  price: number;
  from_precision: number;
  to_precision: number;
};

export type PaginationResult<T> = {
  count: number;
  next: null | string;
  previous: null | string;
  results: T[];
};

export type Quote = {
  from_token_symbol: string;
  to_token_symbol: string;
  from_token_amount: number;
  to_token_amount: number;
  to_token_usdt_price: number;
  estimated_gas: number;
  estimated_gas_dollars: number;
  conversion_rate: number;
};

export type SuccessDataResult<T> = {
  success: boolean;
  data: T;
};

export type SymbolAccountBalance = {
  contract_size: null;
  currency_position: number;
  currency_symbol: string;
  position: number;
  symbol: string;
  leverage: string[];
  margin_mode: string[];
};

export type Team = {
  name: string;
  uuid: string;
};

export type TradingviewSymbol = {
  symbol: "BTCUSDT";
  description: "Bitcoin / TetherUS";
  type: "crypto";
  exchange: "BINANCE";
  currency_code: "USDT";
  "currency-logoid": "crypto/XTVCUSDT";
  "base-currency-logoid": "crypto/XTVCBTC";
  provider_id: "binance";
};

export type FavoriteSymbol = {
  exchange: CefiExchangeId;
  symbol: string;
  asset_class: CefiExchangeMarketType;
  market_type: CefiExchangeMarketType;
  coin1: string;
  coin2: string;
  coin_margin: null;
  position?: number;
  is_derivative: boolean;
};

export type LiquiditySources = {
  [liquiditySourceName: string]: { value: boolean; name: string };
};

export type OrderPreferences = {
  cefi_trading_style: TradingStyle;
  cefi_would_price: WouldStyle;
  cefi_maker_placement_type: PlacementMode;
  cefi_custom_placement_level: string;
  cefi_custom_cancel_level: string;
  cefi_clip_size_type: ClipSizeType;
  cefi_expiration: string;
  cefi_extend_duration: boolean;
  cefi_strategy: OrderExecutionStrategy;
  cefi_duration_type: OrderTradingDurationUnit;
  cefi_duration_value: string;
  defi_parent_slipage_percent: string;
  defi_child_slipage_percent: string;
  defi_clip_size_type: ClipSizeType;
  defi_expiration: string;
  defi_extend_duration: boolean;
  defi_strategy: DefiOrderExecutionStrategy;
  defi_duration_type: OrderTradingDurationUnit;
  defi_duration_value: string;
};

export type UserDetails = {
  user_uuid: string;
  email: string;
  first_name: string;
  last_name: string;
  wallets: null;
  team_uuid: string;
  team_name: string;
  otp_enabled: boolean;
  account_number: string;
  play_sound: boolean;
  send_email: boolean;
  company_name: string;
  telegram_id: string;
  team_telegram_support_link: null | string;
  terms_of_use: boolean;
  max_number_teams: number;
  is_individual_account: boolean;
  fee_manager_enabled: boolean;
  is_referrer?: boolean;
  referral_link_limit?: number;
  referrer_allowed?: boolean;
  referrer_request_sent?: boolean;
  layout_cefi?: WidgetsViewState;
  layout_defi?: WidgetsViewState;
  layout_otc?: WidgetsViewState;
  user_layouts: PredefinedWidgetsLayout[];
  token_pair_watchlist: FavoriteSymbol[];
  preferences: {
    volume_participation_plot: {
      safe_below: number;
      dangerous_above: number;
    };
  };
  order_preference: OrderPreferences;
  liquiditySources: LiquiditySources;
  ip_addresses: string[];
  is_staff: boolean;
  is_analytics_visible: boolean;
  is_post_trade_visible: boolean;
  is_widgets_visible: boolean;
  is_otc_visible: boolean;
  is_fees_dashboard_visible: boolean;
  is_testnet: boolean;
  is_cefi_intro_shown: boolean;
  is_defi_intro_shown: boolean;
  is_widgets_intro_shown: boolean;
  is_cefi_or_defi_modal_shown: boolean;
};

export type GetQuoteResult = {
  quoted_data: Quote;
  success: boolean;
  validated_data: {
    network_id: ChainId;
    from_token: string;
    to_token: string;
    amount: string;
  };
};

export type GetBaseCoinPriceParams = {
  chainId: ChainId;
  ids: string; // ethereum
  vsCurrencies: string; // usd
};

export type GetBaseCoinPriceResult = {
  usd: number;
};

export enum OrdersCurrentTab {
  All = "all",
  Regular = "regular",
  History = "history",
  Trigger = "trigger",
  SavedOrders = "saved",
  Positions = "positions",
  Assets = "assets",
}

export enum GetOrdersPeriod {
  Day = "day",
  Week = "week",
  Month = "month",
  ThreeMonths = "3months",
  All = "all",
}

export enum MetricsGroupBy {
  Days = "days",
  Weeks = "weeks",
  Months = "months",
}

export const MetricsGroupByOptions: AnbotoButtonGroupOption<MetricsGroupBy>[] = [
  { label: "Days", value: MetricsGroupBy.Days },
  { label: "Weeks", value: MetricsGroupBy.Weeks },
  { label: "Months", value: MetricsGroupBy.Months },
];

export type GetDefiOrdersParams = {
  currentTab: OrdersCurrentTab;
  status?: OrderStatuses;
  period?: GetOrdersPeriod;
  limit?: number;
};

export type GetDefiOrdersQueryParams = {
  current_tab: OrdersCurrentTab;
  date_time_limit?: GetOrdersPeriod;
  status?: OrderStatuses;
  limit?: number;
};

export type UserPasswordResetResponse = {
  uid: string | null;
  token: string | null;
  new_password1: string;
  new_password2: string;
};

export type UserPasswordLinkResponse = {
  email: string;
};

export type SocialAccount = {
  count: number;
  next: null;
  previous: null;
  results: {
    date_joined: string;
    id: number;
    last_login: string;
    provider: string;
    uid: string;
    user_email?: string;
  }[];
};

export type CreateTGParams = {
  account_uuid: string;
};

export type CreateTGResult = {
  success: boolean;
};

export type VerifParams = {
  referral_code?: string;
  code?: string;
  email: string;
  is_account: boolean;
};

export type AddParticipantParams = {
  user_email: string;
  invite_code: string;
};

export type CreateAccountParams = {
  email: string;
  invitational_email: string;
  code?: string;
  referral_code?: string;
  password1: string;
  password2: string;
  first_name?: string;
  last_name?: string;
  is_account: number;
  telegram_id?: string;
  phone_number?: string;
  company_name?: string;
  is_individual_account?: boolean;
  trader_type?: TraderType | "";
};

export type SigninParams = {
  captcha_id?: string;
  captcha_output?: string;
  email: string;
  password: string;
  lot_number?: string;
  pass_token?: string;
  gen_time?: number;
};

export type SigninResult = {
  access_token: string;
  refresh_token: string;
  user: UserDetails;
};

export type VerifyInviteResult = {
  account_name: string;
  is_registered: boolean;
  success: boolean;
};

export type GetQuoteParams = {
  fromTokenAddress: string;
  toTokenAddress: string;
  chainId: ChainId;
  payAmount: string;
};

export type GetSwapQuoteParams = {
  chainId: ChainId;
  sellToken: string;
  sellTokenDecimals: number;
  buyToken: string;
  buyAmount: string | undefined;
  buyTokenDecimals: number;
  sellAmount: string | undefined;
  slippage: string;
};

export type GetSwapQuoteResult = {
  chainId: ChainId;
  price: string;
  buyTokenAddress: string;
  sellTokenAddress: string;
  buyAmount: string;
  sellAmount: string;
  sellTokenToEthRate: string;
  buyTokenToEthRate: string;
};

export type SignOrderQueryParams = {
  anbotoContractOrder: AnbotoContractOrder;
  chainId: ChainId;
};

export type SignOrderQueryParamsV2 = {
  anbotoContractOrder: AnbotoContractOrderV2;
  chainId: ChainId;
};

export type ExchangeVolumeDataParams = {
  symbol: string;
  exchange_name: string;
  market_type: string;
  quantity: string;
  frequency: number;
  number_of_slices: string;
};

export type PreTradeAnalysisParams = {
  strategy: OrderExecutionStrategy;
  symbol: string;
  exchange: string;
  asset_class: string;
  quantity: string;
  clip_size_type?: string;
  clip_size_val?: string;
  duration_seconds?: string;
};

export type PreTradeAnalysisAgnosticParams = {
  symbol: string;
  exchange: string;
  asset_class: string;
  strategy?: OrderExecutionStrategy;
};

export type ExchangeVolumeDataResult = {
  success: boolean;
  data: Record<string, string[] | number[]>;
};

type PreTradeAnalysisResultSimulationItem = {
  ts: number;
  simulated: number;
  observed: number;
  danger_level: string;
};

export type PreTradeAnalysisResult = {
  success: boolean;
  data: {
    advise_clip_size: number;
    advice_duration: number; // in seconds
    advise_lower_duration: number;
    clip_size_val: number;
    n_clips: number;
    duration: number;
    volume_participation: number;
    agnostic: {
      volume_analysis: {
        volume: number;
        timeframe: number;
      };
      volatility_analysis: {
        short_term: {
          volatility: number;
          period: string;
        };
        long_term: {
          volatility: number;
          period: string;
        };
      };
    };
    order_book_analysis: {
      safe_orderbook_depth: boolean;
      observed_volatility: number;
      participation_order_book: number;
      advice_duration_safe: number;
      advice_duration_moderate: number;
    };
    simulation: PreTradeAnalysisResultSimulationItem[];
  };
};

export type PreTradeAnalysisAgnosticResult = {
  success: boolean;
  data: {
    contract_size: number;
    volume_analysis: {
      volume: number;
      timeframe: number;
    };
    volatility_analysis: {
      short_term: {
        volatility: number;
        period: string;
      };
      long_term: {
        volatility: number;
        period: string;
      };
    };
    futures_infos: {
      mark_price: number;
      funding_rate: number;
      open_interest: number;
    };
    order_book: {
      asks: OrderbookOrders;
      bids: OrderbookOrders;
      datetime: string;
      nonce: number;
      symbol: string;
      timestamp: number;
    };
  };
};

export enum OrderTradingDurationUnit {
  DAYS = "days",
  HOURS = "hours",
  MINUTES = "minutes",
}

export enum OrderTriggerMode {
  ONCE = "once",
  ALWAYS = "always",
}

export enum OrderRefreshMode {
  BPS = "bps",
  LEVEL = "level",
}

export enum OrderMarginType {
  CROSS = "cross",
  ISOLATED = "isolated",
}

export enum OrderIcebergSpeed {
  FAST = 2,
  MEDIUM = 10,
  SLOW = 30,
}

export enum OrderExpiration {
  GTC = 0,
  DAY = 24 * 3600,
  WEEK = 7 * 24 * 3600,
  MONTH = 30 * 24 * 3600,
}

const now = DateTime.local();

export enum DefiOrderExpiration {
  // years * days * hours * minutes * secondes = 100 years
  // ~ no limit as a deadline needs to be provided for the contract
  // expiration can not be 0, so we set a really number assuming 100 year ~ no limit
  GTC = 100 * 365 * 24 * 60 * 60,
  DAY = 24 * 3600,
  WEEK = 7 * 24 * 3600,
  MONTH = Math.floor(now.endOf("month").diff(now.startOf("month"), "seconds").seconds),
  HALF_YEAR = Math.floor(now.endOf("year").diff(now.startOf("year"), "seconds").seconds),
}

export enum OrderPreferencesExpirations {
  GTC = 0,
  DAY = 24 * 3600,
  WEEK = 7 * 24 * 3600,
  MONTH = 30 * 24 * 3600,
  SIX_MONTH = 6 * 30 * 24 * 3600,
}

export enum PlacementValue {
  DEFAULT = "1",
}

export enum PlacementCancelValue {
  DEFAULT = "4",
  TIGHT = "2",
}

export type GetExchangeSymbolMinAmountDataResult = {
  min_amount: number;
  success: boolean;
  validated_data: { symbol: string; exchange_name: string };
};

export type Wallet = {
  name: string;
  address: string;
  is_show_in_portfolio?: boolean;
};

export type GetWalletsResult = {
  results: Wallet[];
};

export type PortfolioItemType = { item: string | Wallet; isAdded: boolean };

export enum CefiExchangeId {
  BINANCE = "binance",
  BYBIT = "bybit",
  COINBASE = "coinbasepro",
  GATEIO = "gateio",
  KRAKEN = "kraken",
  KUCOIN = "kucoin",
  OKX = "okx",
  ASCENDEX = "ascendex",
  HUOBI = "huobi",
  BITMEX = "bitmex",
  WOOX = "woo",
  BITGET = "bitget",
  MEXC = "mexc",
  B2C2 = "b2c2",
}

export enum PortfolioType {
  CEFI = "cefi",
  DEFI = "defi",
}

export type GetMetricsMonthsResult = {
  metrics_date_start: ISO8601DateTime;
  metrics_months: [string, string][]; // month, date
};

export type GetMonthMetricsResult = {
  schema: {
    pandas_version: string;
    fields: Array<{
      name: string;
      type: string;
    }>;
  };
  data: Record<string, string>[];
};

export type GetGasOracleResult = {
  success: true;
  validated_params: { network_id: ChainId };
  data: Record<GasPriceOptionType, number>;
};

export type UserNotificationSettings = {
  play_sound: boolean;
  send_email: boolean;
  safe_below: number;
  dangerous_above: number;
};

export type RefreshTokenResult = {
  access: string;
  refresh: string;
};

export type GetMarketDataParams = {
  timeframe: string;
  since: number;
  symbol: string;
  exchange_id: CefiExchangeId;
  market_type: CefiExchangeMarketType;
  limit?: number;
};

// time, open, high, low, close, volume
export type MarketDataItem = [number, number, number, number, number, number];

export type GetMarketDataResult = GetMarketDataParams & {
  data: MarketDataItem[];
  limit: null | number;
};

export type Toggle2faParams = { userId: string; twoFaCode: string; otp_enabled: boolean };

export type UserAccountMember = {
  email: string;
  first_name: string;
  last_name: string;
  user_uuid: string;
  company_name: string;
  telegram_id: string;
};

export type UserPermissions = {
  user_uuid: string;
  read_only: boolean;
};

export type UserAccountsResult = {
  uuid: string;
  name: string;
  account_number: string;
  admin_email: string;
  admin_uuid: string;
  nb_of_members_joined: string;
  nb_of_members_total: string;
  is_individual: boolean;
  invited_members: UserAccountMember[];
  members: UserAccountMember[];
  permissions: UserPermissions[];
};

export type UserAsset = {
  balance: string;
  balanceRawInteger: string;
  balanceUsd: string;
  blockchain: string;
  contractAddress?: string;
  holderAddress: string;
  thumbnail: string;
  tokenDecimals: number;
  tokenName: string;
  tokenPrice: string;
  tokenSymbol: string;
  tokenType: string;
};

export type UserTokensResult = {
  id: number;
  jsonrpc: string; // 2.0
  result: {
    assets: UserAsset[];
    totalBalanceUsd: string;
    totalCount: number;
  };
};

export type Holder = {
  holderAddress: string;
  balance: string;
  balanceRawInteger: string;
};

export type GetTopTokenHoldersResult = {
  id: number;
  jsonrpc: string;
  result: {
    blockchain: string;
    contractAddress: string;
    holdersCount: number;
    tokenDecimals: number;
    syncStatus: {
      timestamp: number;
      lag: string;
      status: string;
    };
    holders: Holder[];
  };
};

// v3
export enum PovRisk {
  DEFAULT = "default",
  LOW = "low",
  HIGH = "high",
}

export enum ClipSizeType {
  AUTOMATIC = "automatic",
  ABSOLUTE = "absolute",
  PERCENTAGE = "percentage",
  NB_OF_CHILD_ORDERS = "nb_of_child_orders",
}

export enum TraderType {
  INSTITUTIONAL = "institutional",
  EXPERIENCED_INDIVIDUAL = "experienced_individual",
  STARTER = "starter",
}

export enum ExtendDurationType {
  YES = "true",
  NO = "false",
}

export enum ParentOrderStatuses {
  PENDING_NEW = "pending_new",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  PARTIALLY_FILLED = "partially_filled",
  FILLED = "filled",
  PENDING_CANCEL = "pending_cancel",
  CANCELLED = "cancelled",
  PENDING_PAUSE = "pending_pause",
  PAUSED = "paused",
  INSUFFICIENT_FUNDS = "insufficient_funds",
  PENDING_UNPAUSE = "pending_unpause",
}

export enum ParentOrderErrorCode {
  EXCHANGE_ERROR = "exchange_error",
  QUANTITY_EXCEED = "quantity_exceed",
  AUTHENTICATION_ERROR = "authentication_error",
  INSUFFICIENT_FUNDS = "insufficient_funds",
  RATE_LIMIT_EXCEEDED = "rate_limit_exceeded",
  DDOS_PROTECTION = "ddos_protection",
  EXCHANGE_NOT_AVAILABLE = "exchange_not_available",
  NETWORK_ERROR = "network_error",
  INVALID_ORDER = "invalid_order",
  OTHER = "other",
  EXPIRY_REACHED = "expiry_reached",
}

export type TransactionsFeesTableType = {
  date: string;
  value: {
    taker: number | string;
    maker: number | string;
  };
  fees: {
    taker: number | string;
    maker: number | string;
  };
};

export type ParentOrder = {
  client_order_id: string;
  order_id: string;
  account_uuid: string;
  subaccount: string | null;
  exchange: string;
  trader: string;
  trader_email: string;
  source: string;
  symbol: string;
  side: OrderSide;
  quantity: number;
  strategy: OrderExecutionStrategy;
  start_time: number;
  end_time: number;
  clip_size_type: ClipSizeType;
  clip_size_val: number;
  asset_class: CefiExchangeMarketType;
  is_pta_available: boolean;
  last_status: {
    status: ParentOrderStatuses;
    last_price: number;
    average_price: number;
    last_quantity: number;
    filled_quantity: 0;
    leaves_quantity: 0.009;
    error_code: ParentOrderErrorCode;
  };
  limit_price: number;
  expiration_time: number;
  created_at?: string;
  params: {
    reduce_only?: boolean;
    pov_risk: PovRisk;
    pov_ratio: number;
    duration_seconds: number;
    extend_duration?: boolean;
    maker_address?: string;
    urgency: Urgency;
    would: {
      would_pct: number;
      would_price: number;
      would_style: WouldStyle;
    };
    trigger: {
      trigger_price: number;
      trigger_condition: TriggerCondition;
    };
    trading_style: TradingStyle;
    placement_infos: {
      cancel: number;
      placement: number;
      placement_mode: PlacementMode;
    };
    randomize_amount: true;
    smart_contract_order?: {
      deadline: number;
      feeAmount: string;
      inputToken: string;
      maxGasPrice: number;
      outMin: string;
      outputToken: string;
      salt: string;
      totalAmount: string;
      isFeeTakenInInput: boolean;
    };
  };
};

type Nullable<T> = T | null;

export type ParentDefiOrder = {
  account_uuid: string;
  aggregator: string;
  asset_class?: "spot";
  client_order_id?: string;
  clip_size_type?: ClipSizeType;
  clip_size_val?: Nullable<string>;
  created_at?: string;
  defi_data?: {
    deadline: number;
    max_fee_absolute?: string;
    is_fee_taken_in_input: boolean;
    maker_address: string;
    max_gas_price?: string;
    out_min: string;
    salt: number;
    signature: string;
    slippage_percentage: string;
    total_amount: string;
    wallet_protocol?: {
      appVersion: string;
      provider: string;
    };
    selected_aggregators?: string[];
    is_private?: boolean;
    fee_percent: number;
  };
  duration_seconds?: number;
  end_time?: number;
  expiration_time?: number;
  fee_urgency: string;
  is_trigger?: boolean;
  last_status?: {
    average_price?: Nullable<number>;
    created_at?: number;
    error_code?: Nullable<ParentOrderErrorCode>;
    filled_quantity?: number;
    last_price?: Nullable<number>;
    last_quantity?: Nullable<number>;
    leaves_quantity?: number;
    status?: ParentOrderStatuses;
    message?: string;
  };
  limit_price?: Nullable<string>;
  network_id: ChainId;
  order_id?: string;
  params: {
    extend_duration?: boolean;
    placement_infos_cancel?: Nullable<string>;
    placement_infos_placement?: Nullable<string>;
    placement_infos_placement_mode?: Nullable<string>;
    trading_style?: Nullable<string>;
    trigger_condition?: Nullable<TriggerCondition>;
    trigger_price?: Nullable<string>;
    would_pct?: Nullable<string>;
    would_price?: Nullable<string>;
    would_style?: Nullable<string>;
  };
  quantity: number;
  side: OrderSide;
  source?: string; // "web-ui";
  start_time?: number;
  strategy: OrderExecutionStrategy;
  symbol: string; // "USDT/WETH"
  trader_uuid?: string;
  trader_email?: string;
  token1?: string;
  token2?: string;
};

export type AllParentOrder = ParentDefiOrder & {
  order_type: "cefi" | "defi";
  subaccount: string;
  exchange: string;
  trader: string;
  params: {
    reduce_only?: boolean;
    duration_seconds: number;
    would: {
      would_pct: number;
      would_price: number;
      would_style: WouldStyle;
    };
    placement_infos: {
      cancel: number;
      placement: number;
      placement_mode: PlacementMode;
    };
    trigger: {
      trigger_price: number;
      trigger_condition: TriggerCondition;
    };
    pov_risk;
    pov_ratio;
    urgency?: Urgency;
  };
};

export type ParentOrderCancelParams = {
  accountId: string;
  orderId: string;
};

export type GetParentOrdersParams = {
  accountId: string;
  currentTab: OrdersCurrentTab;
  period: GetOrdersPeriod;
  limit?: number;
  status: OrderStatuses | ParentOrderStatuses | DefaultStatusFilter;
  exchangeIds?: CefiExchangeId[];
};

export type GetCefiParentOrdersParams = GetParentOrdersParams;

export type GetAllParentOrdersParams = GetParentOrdersParams & {
  exchange_list_ids?: string;
  offset?: number;
  side?: string;
  type?: string;
  strategy?: string;
};

export type GetDefiParentOrdersParams = GetParentOrdersParams & {
  exchange_list_ids: string;
};

export enum ParentOrderMsgCode {
  CREATE = 1,
  UPDATE = 2,
}

export enum WouldStyle {
  PASSIVE = "passive",
  HYBRID = "hybrid",
  AGGRESSIVE = "aggressive",
}

export enum PlacementMode {
  DEFAULT = "default",
  TIGHT = "tight",
  CUSTOM = "custom",
}

export enum TriggerCondition {
  ABOVE = "above",
  BELOW = "below",
}

export enum TradingStyle {
  PASSIVE = "passive",
  HYBRID = "hybrid",
  AGGRESSIVE = "aggressive",
}

export type ParentOrderFormParams = ParentOrderFormFieldValues & { accountId: "string" };
export type CefiAllocation = {
  balance: number;
  exchange_id: string;
  market_type: string;
  portion: number;
  price: number;
  subaccount: string | null;
  token_symbol: string;
  value: number;
  expandedInfo?: iCefiAllocationItem[] | undefined;
  is_position?: boolean;
  tradeLink?: string;
};

export interface iCefiAllocationItem {
  exchange_id: string;
  balance: number;
  value: number;
}

export interface iDefiAllocationItem {
  address: string;
  balance: number;
  value: number;
}

export type DefiAllocation = {
  balance: number;
  wallet_address: string;
  market_type: string;
  portion: number;
  price: number;
  subaccount: string | null;
  token_symbol: string;
  value: number;
  protocol: string | null;
  chain_name: string;
  expandedInfo?: iDefiAllocationItem[] | undefined;
  icon: string;
};

export type TokenDataWithIcon = {
  coinName?: string;
  symbol?: string;
  url?: string;
  chainIconUrl?: string;
};

export type CefiAllocationWithIcon = CefiAllocation & TokenDataWithIcon;
export type DefiAllocationWithIcon = DefiAllocation & TokenDataWithIcon;

export type GetExchangesSymbolData = {
  min_price: number | null;
  max_price: number | null;
  min_amount: number | null; // 0.00048
  max_amount: number | null; // 9000.0
  min_cost: number | null; // 10.0
  amount_precision: number | null; // 5
  price_precision: number | null; // 2
  contract_size: number | null; // null;
  min_notional: number | null; // 10;
};

export type GetExchangesSymbolDataResponse = {
  success: boolean;
  data: GetExchangesSymbolData;
};

export interface FutureAssets {
  positions?: CefiAllocation[];
  margin: CefiAllocation[];
  balances: { future: number; position: number; spot: number };
}

export type AlgoliaSearchCefiResult = {
  coin1: string;
  coin2: string;
  exchange: string;
  market_type: string;
  objectID: string;
  symbol: string;
};

export type GetAssetsTradeLinks = {
  tradeLinks: Record<string, string | undefined> | undefined;
  isFetching: boolean;
};

export type GetFundingRatesParams = {
  symbol?: string;
  exchange_name?: string;
  market_type?: string;
};

export type GetGasAmountEstimationParams = {
  chainId: ChainId;
};

export type GasAmountEstimation = {
  average: string;
  min: string;
  max: string;
};

export type GetGasAmountEstimationResult = {
  data: {
    [network_key: string]: GasAmountEstimation;
  };
};

export type FundingRate = {
  symbol: string;
  time: string;
  rate: number;
};

export type GetFundingRatesResult = {
  historical_data: FundingRate[];
  last_snapshot: FundingRate[];
};
export type StartSavedOrderParams = { order_id: string; account_uuid: string };

export type DeleteSavedOrderParams = { order_id: string; account_uuid: string };

type LiquidityItem = {
  exchange_id: string;
  id: number;
  is_defi: boolean;
  is_passphrase: boolean;
  is_subaccounts_support: boolean;
  name: string;
  stable_coins: any[];
};

export type GetLiquiditySourcesResult = { count: number; results: LiquidityItem[] };

export type Promo = {
  uuid: string;
  ad_type: string;
  title: string;
  description: string;
  text_position: "top" | "bottom" | "center";
  link: string | null;
  image: string;
  image_width: number;
  image_height: number;
};

export type GetPromosResult = {
  popup_ads: Promo[];
  banner_ads: Promo[];
};

export type GetMetricsTradeParams = {
  start_time: string;
  end_time: string;
};

type MetricsItem = {
  average_fee: number;
  executed_usd: number;
  fee_cost_usd_hist: number;
  fee_cost_usd_now: number;
};

export type Metrics = {
  taker_or_maker: {
    maker: MetricsItem;
    taker: MetricsItem;
  };
  side: {
    buy: MetricsItem;
    sell: MetricsItem;
    gross: MetricsItem;
    net: MetricsItem;
  };
  exchange_id: {
    [exchangeName: string]: MetricsItem;
  };
  strategy: {
    [strategyName: string]: MetricsItem;
  };
  trader_id: {
    [traderName: string]: MetricsItem;
  };
  asset_class: {
    future: MetricsItem;
    spot: MetricsItem;
  };
  is_defi: {
    True: MetricsItem;
    False: MetricsItem;
  };
  overall: MetricsItem;
  anboto_fees: number;
};

export type GetMetricsTradeResult = {
  success: boolean;
  data: Metrics;
};

export type GetMetricsTradesGroupedParams = {
  n_groups: number;
  group_by: GroupBy;
  end_time: string;
  exchange?: string;
  is_defi?: boolean | undefined;
  strategy?: string;
  asset_class?: string;
};

export type GetMetricsTradesGroupedResult = {
  data: {
    [date: string]: Metrics;
  };
};

export type MetricsTradesGrouped = {
  [date: string]: Metrics;
};

export type SideChartItem = { buy: number; sell: number; net: number; gross: number; date: number };

export type TradesChartData = {
  trades: number;
  cumulativeSum: number;
  date: number;
};

export type TransactionsAndFeesTableItem = {
  date: number;
  executed_usd: { taker: number; maker: number };
  fee_cost_usd_now: { taker: number; maker: number };
};

export type MetricsPageOrderExportParams = { account_uuid: string; order_id: string };

export type ValidateApiKeyResult = {
  is_valid: boolean;
  error: ExchangeApiError;
  uuid: string;
};

export type ValidateApiKeyParams = {
  api_uuid: string;
};

export type ValidateApiKeysParams = {
  api_uuids: string[];
};

export type ValidateApiKeysResult = {
  data: { [uuid: string]: ValidateApiKeyResult };
};

export type CreateExchangeConfigResult = {
  uuid: string;
  team_uuid: string;
  exchange: string;
  exchange_name: string;
  exchange_id: string;
  subaccount_title: string;
  error: ExchangeApiError;
  api_keys_stars: {
    api_key: string;
    secret_key: string;
  };
};

export type CreateExchangeConfigParams = {
  exchange: number;
  apiKey: string;
  secretKey: string;
  subaccountTitle: string;
  password?: string;
};

export type MonthlySummaryTableItem = {
  month: string;
  tradedValue: number;
  tradedValueCumulative: number;
  buyValue: number;
  sellValue: number;
  tradedNet: number;
  tradedMaker: number;
  tradedTaker: number;
  DefiTotal: number;
  CefiTotal: number;
  accumulatedVolume: number;
  date: number;
};

export type GetPostTradeAnalysisDataParams = {
  parent_id: string;
};

export type FeesPostTradeAnalysis = {
  fees_paid_usd: number;
  notional_executed: number;
  fees_in_bps_notional: number;
  fees_by_coin: {
    [coinName: string]: number;
  };
  posit_bal_exch_coin: boolean;
  taker_fees_infos: {
    fees_paid_usd: number;
    notional_executed: number;
    fees_in_bps_notional: number;
  };
  maker_fees_infos: {
    fees_paid_usd: number;
    notional_executed: number;
    fees_in_bps_notional: number;
  };
};

export type SlippagesPostTradeAnalysis = {
  arrival: number;
  twap: number;
  vwap: number;
  impacted_price: number;
};
export type PricesPostTradeAnalysis = {
  arrival: number;
  twap: number;
  vwap: number;
  avg_exec_price: number;
  impacted_price: number;
};

export type CostOfRepricingTradeAnalysis = {
  maker_taker_savings: number;
  spread_savings: number;
  cost_of_repricing: number;
  net_savings: number;
  pct_orders_cancelled: number;
};

export type PostTradeAnalysis = {
  fees: FeesPostTradeAnalysis;
  plot: {};
  prices: PricesPostTradeAnalysis;
  slippages: SlippagesPostTradeAnalysis;
  reversion_5min_bps: number;
  volume_participation: number;
  spread_capture: any;
  percent_passive: {
    pct_passive_count: number;
    pct_passive_notional: number;
  };
  cost_of_repricing: CostOfRepricingTradeAnalysis;
};

export type GetPostTradeAnalysisDataResult = {
  data: PostTradeAnalysis;
};

export type LightChartsData = { time: UTCTimestamp; value: number }[];

export type CompetitionParticipant = {
  account_number: string;
  volume: number;
  is_trader: boolean; // true if current user is the user who requesting this data
};

export type GetLeaderBoardResult = {
  [competitionName: string]: CompetitionParticipant[];
};

export interface GetPreTradeAnalysisDefiParams {
  symbol: string;
  chainId?: ChainId;
  quantity: number;
  clipSizeType?: ClipSizeType;
  durationSeconds?: number;
  childSlippage: string;
}

export type PreTradeAnalysisDefiResult = {
  success: boolean;
  data: {
    clip_size_val: number;
  };
};

export interface GetPreTradeAnalysisDefiAwareParams {
  symbol: string;
  quantity: number;
  child_slippage: string;
  chainId: ChainId;
  clip_size_type?: ClipSizeType;
  clip_size_val?: string;
  strategy: OrderExecutionStrategy;
  durationSeconds?: number;
}

export type PreTradeAnalysisDefiAwareResult = {
  success: boolean;
  data: {
    agnostic: {};
    clip_size_val: string;
    n_clips: string;
    use_clip_size: boolean;
    advise_clip_size: string;
    expected_slippage: string;
  };
};

export type SetOrderPreferencesParams = {
  team_uuid: string;
  cefi_trading_style: TradingStyle;
  cefi_would_price: WouldStyle;
  cefi_maker_placement_type: PlacementMode;
  cefi_custom_placement_level: string;
  cefi_custom_cancel_level: string;
  cefi_clip_size_type: ClipSizeType;
  cefi_expiration: OrderPreferencesExpirations;
  cefi_extend_duration: boolean;

  cefi_strategy: OrderExecutionStrategy;
  cefi_duration_type: OrderTradingDurationUnit;
  cefi_duration_value?: string;

  defi_strategy: DefiOrderExecutionStrategy;
  defi_duration_type: OrderTradingDurationUnit;
  defi_duration_value?: string;
  defi_parent_slipage_percent: string;
  defi_child_slipage_percent?: string;
  defi_clip_size_type: ClipSizeType;
  defi_expiration: OrderPreferencesExpirations;
  defi_extend_duration: boolean;
};

export type GetSwapQuoteWithPreTradeAnalysisDefiAwareParams = {
  chainId: ChainId;
  sellToken: string;
  sellTokenDecimals: number;
  buyToken: string;
  buyTokenDecimals: number;
  sellAmount: string | undefined;
  childSlippage: string;
  clip_size_type?: ClipSizeType;
  clip_size_val?: string;
  durationSeconds?: number;
  strategy: OrderExecutionStrategy;
};

export type GetSwapQuoteWithPreTradeAnalysisDefiAwareData = {
  chainId: ChainId;
  buyAmount: string;
  sellAmount: string;
  price: string;
  buyTokenAddress: string;
  sellTokenAddress: string;
  sellTokenToEthRate: string;
  buyTokenToEthRate: string;
  agnostic: any;
  clip_size_val: string;
  n_clips: string;
  use_clip_size: boolean;
  expected_slippage: string;
  advise_clip_size: string;
  new_output: string;
  price_per_child: number;
  order_benefit: string;
};

export type GetSwapQuoteWithPreTradeAnalysisDefiAwareResult = {
  success: boolean;
  data: GetSwapQuoteWithPreTradeAnalysisDefiAwareData;
};

export type FeeCollects = any;

export type TradingMonthlyActivity = {
  date: number;
  daily_cefi_volume: number;
  daily_defi_volume: number;
  total_daily_volume: number;
}[];

export type AccountStatuses = {
  account_name: string;
  account_number: string;
  amount_collected: number;
  amount_due: number;
  balance_before_collection: number;
  cefi_fee_rate: string;
  defi_fee_rate: string;
  due_cefi_volume: number;
  due_defi_volume: number;
  charged_cefi_volume: number;
  charged_defi_volume: number;
};

export type TradingActivity = {
  account_name: string;
  account_number: string;
  daily_cefi_volume: number;
  daily_defi_volume: number;
  exchange_ids: string;
};

export type GetDailyDashboardResult = {
  account_statuses: AccountStatuses[];
  trading_activity: TradingActivity[];
};

export type GetMonthlyDashboardResult = any;

export type GetFeeManagerBalancesResults = {
  chains: {
    network_id: number;
    network_name: string;
    balance_usd: number;
  }[];
};

export type GetAnkrContractResults = {
  account_statuses: AccountStatuses[];
  trading_activity: TradingActivity[];
};

export enum Urgency {
  HIGH = "High",
  MEDIUM = "Medium",
  LOW = "Low",
}

export type GetPostTradeAnalysisMetricsDataParams = {
  start: number;
  end: number;
  account: number;
  exchanges?: string[];
  strategies?: string[];
  sides?: string[];
  spot_or_future?: string[];
};

export type GetPostTradeAnalysisMetricsDataResult = {
  success: true;
  data: {
    result: {
      number_of_parents: number;
      fees: {
        total_usd: number;
        maker_usd: number;
        taker_usd: number;
      };
      total_notional: number;
      slippages: {
        twap_average: number;
        vwap_average: number;
        arrival_average: number;
        impacted_price_average: number;
      };
      reversion_5min_bps_average: number;
      volume_participation_average: number;
      percent_passive: {
        pct_passive_notional_average: number;
      };
      cost_of_repricing: {
        pct_orders_cancelled_average: number;
        maker_taker_savings_average: number;
        spread_savings_average: number;
        cost_of_repricing_average: number;
        net_savings_average: number;
        maker_taker_savings_sum: number;
        spread_savings_sum: number;
        cost_of_repricing_sum: number;
        net_savings_sum: number;
      };
      bid_ask_spread: {
        average: number;
        twap_vs_spread: number;
        arrival_vs_spread: number;
      };
    };
  };
};
